<template>
  <div v-if="badges && badges.length > 0" class="product-badges">
    <Tag
      v-for="badge in badges"
      :key="productId + '-' + badge.id"
      :color="badge.color"
      :label="badge.name"
    />
  </div>
</template>

<script>
export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
    badges: { type: Array, default: null },
  },
};
</script>

<style>
.product-badges {
  > .tag {
    @apply me-1 mb-1;
  }
}
</style>

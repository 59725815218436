<template>
  <div class="tag" :class="`tag--${color}`">
    <span class="tag__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    color: { type: String, default: null },
  },
};
</script>

<style>
.tag {
  background: var(--light);
  color: var(--dark);
  @apply inline-flex px-2 py-2 items-center rounded align-top dark:bg-gray-800 dark:text-gray-400;
}

.tag__label {
  @apply font-medium text-xxs;
}

.tag--orange {
  --light: #fff3e0;
  --dark: #fb8c00;
}
.tag--amber {
  --light: #fff8e1;
  --dark: #ffa000;
}
.tag--lime {
  --light: #f9fbe7;
  --dark: #afb42b;
}
.tag--green {
  --light: #e8f5e9;
  --dark: #388e3c;
}
.tag--cyan {
  --light: #e0f7fa;
  --dark: #0097a7;
}
.tag--blue {
  --light: #e3f2fd;
  --dark: #1976d2;
}

.tag--indigo {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--purple {
  --light: #f3e5f5;
  --dark: #7b1fa2;
}

.tag--pink {
  --light: #fce4ec;
  --dark: #c2185b;
}

.tag--red {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--brown {
  --light: #efebe9;
  --dark: #6d4c41;
}

.tag--grey {
  --light: #f3f4f6;
  --dark: #4b5863;
}
</style>
